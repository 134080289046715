import React, { Component } from 'react'
import qs from 'qs'
import { getCallbackUrl } from './util'
import SocialButton from './components/SocialLogin'
import { GOOGLE_APP_ID } from './const'
import Container from './components/Container'
import { GoogleLoginButton } from 'react-social-login-buttons'
import { handleRedirectUrl } from './url-handler'

class App extends Component {
  handleRedirect(filepath) {
    const callbackUrl = getCallbackUrl()
    const queryString = qs.stringify({ filepath })
    const redirectUrl = `${callbackUrl}?${queryString}`
    console.log({ redirectUrl })
    handleRedirectUrl(redirectUrl)
  }
  handleSocialLogin(user) {
    console.log('>> handleSocialLogin', user)
  }
  handleSocialLoginFailure(err) {
    console.log('>> handleSocialLoginFailure', err)
  }
  componentDidMount() {
    setTimeout(() => {
      this.refs.socialButton.login()
    }, 1000)
  }
  render() {
    return (
      <Container>
        <SocialButton
          provider="google"
          //appId="444060092383843"
          appId={GOOGLE_APP_ID}
          scope="https://www.googleapis.com/auth/youtube"
          onLoginSuccess={this.handleSocialLogin}
          onLoginFailure={this.handleSocialLoginFailure}
          ref={'socialButton'}
        >
          <GoogleLoginButton>
            {/* <span>Custom text</span> */}
          </GoogleLoginButton>
        </SocialButton>
      </Container>
    )
  }
}

export default App
