import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  width: 100%;
  background-color: rgb(238, 238, 238);
`

export default Container
