import React, { Component } from 'react'
import qs from 'qs'
import { getCallbackUrl } from './util'
import SocialButton from './components/SocialLogin'
import { FACEBOOK_APP_ID } from './const'
import Container from './components/Container'
import { FacebookLoginButton } from 'react-social-login-buttons'
import { handleRedirectUrl } from './url-handler'

class App extends Component {
  handleRedirect = data => {
    const callbackUrl = getCallbackUrl()
    const queryString = qs.stringify({ data })
    const redirectUrl = `${callbackUrl}?${queryString}`
    console.log(redirectUrl)
    handleRedirectUrl(redirectUrl)
  }
  handleSocialLogin = user => {
    console.log('>> handleSocialLogin', user)
    this.handleRedirect(user._token)
  }
  handleSocialLoginFailure = err => {
    console.log('>> handleSocialLoginFailure', err)
    this.handleRedirect()
  }
  componentDidMount() {
    setTimeout(() => {
      this.refs.socialButton.login()
    }, 1000)
  }
  render() {
    return (
      <Container>
        <SocialButton
          provider="facebook"
          //appId="444060092383843"
          appId={FACEBOOK_APP_ID}
          scope="public_profile,email,manage_pages,read_insights"
          onLoginSuccess={this.handleSocialLogin}
          onLoginFailure={this.handleSocialLoginFailure}
          //ref={btn => (this.inputElement = btn)}
          ref={'socialButton'}
        >
          <FacebookLoginButton />
        </SocialButton>
      </Container>
    )
  }
}

export default App
