import { BrowserRouter as Router, Route } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'

import { SettingPage } from './config/SettingPage'
import { Spotify } from './Spotify'
import { SpotifyCallback } from './SpotifyCallback'
import { Starter } from './Starter.js'
import * as serviceWorker from './serviceWorker'
import App from './App'
import Auth0 from './Auth0'
import Facebook from './Facebook'
import Google from './Google'
import Instragram from './Instragram'
import LoginCallback from './LoginCallback'
import Uploader from './Uploader'

import './index.css'

ReactDOM.render(
  <Starter>
    <Router>
      <div>
        <Route exact path="/" component={App} />
        <Route exact path="/app" component={App} />
        <Route exact path="/uploader" component={Uploader} />
        <Route exact path="/facebook" component={Facebook} />
        <Route exact path="/google" component={Google} />
        <Route exact path="/login/callback" component={LoginCallback} />
        <Route exact path="/logout/callback" component={LoginCallback} />
        <Route exact path="/login/:provider" component={Auth0} />
        <Route exact path="/connect/spotify" component={Spotify} />
        <Route
          exact
          path="/connect/spotify/callback"
          component={SpotifyCallback}
        />
        <Route exact path="/ig" component={Instragram} />
        <Route exact path="/settings" component={SettingPage} />
      </div>
    </Router>
  </Starter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
