import { Component } from 'react'
import getWebAuth from './webAuth'
import { getCallbackUrl, getParams } from './util'

class Auth0 extends Component {
  componentDidMount() {
    const { provider } = this.props.match.params
    const callbackUrl = getCallbackUrl()
    const query = getParams()
    const origin = window.location.origin
    const redirectUri = `${origin}/login/callback?socialConnect=${
      query.socialConnect
    }&platform=${query.platform}&callback=${encodeURIComponent(callbackUrl)}`
    console.log('==============================')
    console.log('[provider]', provider)
    console.log('[redirectUri]', redirectUri)
    console.log('[callbackUrl]', callbackUrl)
    console.log('==============================')
    const webAuth = getWebAuth()
    if (provider === 'email') {
      webAuth.authorize({
        connection: 'Username-Password-Authentication',
        email: query.email,
        password: query.password,
        redirectUri: 'https://wavescool.firebaseapp.com',
      })
    }

    if (provider === 'twitter') {
      webAuth.authorize({
        connection: 'twitter',
        redirectUri,
      })
    }

    if (provider === 'google-login' || provider === 'youtube-login') {
      webAuth.authorize({
        connection: 'google-oauth2',
        responseType: 'token',
        redirectUri,
      })
    }

    // Instgram deprecated instagram_basic API and scope on une 29, 2020
    // Use Facebook API for those permissions now
    // https://auth0.com/docs/connections/social/instagram
    // https://developers.facebook.com/docs/instagram-api/getting-started/

    if (provider === 'facebook-login' || provider === 'instagram-login') {
      webAuth.authorize({
        connection: 'facebook',
        redirectUri,
      })
    }

    if (provider === 'facebook' || provider === 'instagram' || provider === 'facebook-instagram') {
      webAuth.authorize({
        connection: 'facebook',
        redirectUri,
        // connection_scope: 'user_link,manage_pages,read_insights',
        connection_scope: 'user_link,manage_pages,read_insights,pages_show_list,instagram_basic,instagram_manage_insights',
      })
    }

    // if (provider === 'instagram' || provider === 'facebook-instagram') {
    //   webAuth.authorize({
    //     connection: 'facebook',
    //     redirectUri,
    //     connection_scope:
    //       'manage_pages,pages_show_list,instagram_basic,instagram_manage_insights',
    //   })
    // }

    // if (provider === 'instagram') {
    //   webAuth.authorize({
    //     connection: 'instagram',
    //     responseType: 'token',
    //     redirectUri: `${redirectUri}&next=login/facebook-instagram`,
    //   })
    // }

    // if (provider === 'instagram-login') {
    //   webAuth.authorize({
    //     connection: 'instagram',
    //     responseType: 'token',
    //     redirectUri,
    //   })
    // }

    if (provider === 'google' || provider === 'youtube') {
      webAuth.authorize({
        connection: 'google-oauth2',
        responseType: 'token',
        accessType: 'offline',
        approvalPrompt: 'force',
        redirectUri,
        connection_scope:
          'https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly',
      })
    }
  }
  render() {
    return null
  }
}

export default Auth0
