import { Component } from 'react'
import qs from 'qs'

import { getParams } from './util'
import { handleRedirectUrl } from './url-handler'

export class SpotifyCallback extends Component {
  componentDidMount() {
    const params = getParams()
    const { state, ...restParams } = params
    const stateData = JSON.parse(window.localStorage.getItem(state))
    if (stateData) {
      window.localStorage.removeItem(state)

      const { callbackUrl } = stateData
      const existingQuery = qs.parse(
        callbackUrl.split('?').length > 1 ? callbackUrl.split('?')[1] : '',
      )
      const queryString = qs.stringify({ ...existingQuery, ...restParams })
      const redirectUrl = `${callbackUrl.split('?')[0]}?${queryString}`
      console.log('redirectUrl', redirectUrl)
      handleRedirectUrl(redirectUrl, { loop: true })
    } else {
      // error
    }
  }
  render() {
    return null
  }
}
