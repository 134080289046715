import { Component } from 'react'
import qs from 'qs'
import * as filestack from 'filestack-js'
import { getCallbackUrl, getParams } from './util'
import './Uploader.css'
import { handleRedirectUrl } from './url-handler'
import { getAppConfig } from './config/app-config.js'

var wait = ms => new Promise((r, j) => setTimeout(r, ms))

const pollingFilestack = async (client, handle, fileSizeInMb, counter = 0) => {
  if (counter > 20 + fileSizeInMb) {
    throw new Error("fail to get file's GCS key")
  }
  await wait(500)
  console.log('polling', handle, 'retry', counter)
  const response = await client.retrieve(handle, {
    metadata: true,
    head: false,
    cache: false,
    dl: false,
  })
  if (response.key) {
    return response
  } else {
    return pollingFilestack(client, handle, fileSizeInMb, counter + 1)
  }
}

class App extends Component {
  handleRedirect({ result = {} }) {
    const callbackUrl = getCallbackUrl()
    const queryString = qs.stringify(result)
    const redirectUrl = `${callbackUrl}?${queryString}`
    console.log({ redirectUrl })
    handleRedirectUrl(redirectUrl)
  }
  componentDidMount() {
    const params = getParams()
    const { source } = params
    const client = filestack.init(getAppConfig().filestack.apiKey, {
      sessionCache: true,
    })
    const defaultSources = [
      'dropbox',
      'googledrive',
      'box',
      'local_file_system',
    ]
    const sources = [
      source,
      ...defaultSources.filter(s => s !== source),
    ].filter(s => defaultSources.includes(s))
    const options = {
      accept: ['audio/*'],
      maxFiles: 1,
      uploadInBackground: false,
      onOpen: () => console.log('opened!'),
      onFileSelected: file => {
        console.log('[onFileSelected]', file)
        const extension = file.filename.split('.').pop()
        console.log('[onFileSelected]', { file, extension })
        return {
          ...file,
          filename:
            file.filename
              .replace(`.${extension}`, '')
              .replace(/[^\w]/g, '_')
              .split(' ')
              .join('_') +
            '.' +
            extension,
        }
      },
      onUploadDone: async res => {
        const fileResult = res.filesUploaded[0]
        console.log({ res })
        if (fileResult.key) {
          this.handleRedirect({ result: fileResult })
        } else {
          console.log('fileresult', fileResult)
          // HACK : file stack havn't finish upload to GCS; try polling
          const response = await pollingFilestack(
            client,
            fileResult.handle,
            fileResult.size % 1000000,
          )
          this.handleRedirect({
            result: {
              ...fileResult,
              key: response.key,
              container: response.container,
            },
          })
        }
      },
      storeTo: {
        location: 'gcs',
        container: getAppConfig().filestack.bucket,
        path: '/',
        access: 'private',
      },
      fromSources: sources,
    }
    client.picker(options).open()
  }
  render() {
    return null
  }
}

export default App
