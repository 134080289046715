import { Component } from 'react'
import qs from 'qs'
import { getCallbackUrl, getParams } from './util'
import { getWebAuth } from './webAuth'
import { handleRedirectUrl } from './url-handler'

class Auth0 extends Component {
  state = { text: '' }
  handleRedirect = token => {
    const params = getParams()
    const { next, socialConnect } = params
    const callbackUrl = getCallbackUrl()
    const existingQuery = qs.parse(
      callbackUrl.split('?').length > 1 ? callbackUrl.split('?')[1] : '',
    )
    const { social_tokens } = existingQuery
    let queryObj = {}

    if (socialConnect === 'true') {
      if (next || social_tokens) {
        let newSocialTokens
        if (!social_tokens) {
          newSocialTokens = [token]
        } else {
          newSocialTokens = [...social_tokens, token]
        }
        queryObj = { social_tokens: newSocialTokens }
      } else {
        queryObj = { social_token: token }
      }
    }
    queryObj = { ...existingQuery, ...queryObj }

    const queryString =
      socialConnect === 'true'
        ? qs.stringify(queryObj)
        : qs.stringify({ token, ...queryObj })
    const redirectUrl = `${callbackUrl.split('?')[0]}?${queryString}`
    console.log('==========================================')
    console.log('[LoginCallback - callbackUrl]', callbackUrl)
    console.log('[LoginCallback - redirectUrl]', redirectUrl)
    console.log('==========================================')
    console.log(['sysend'], window.sysend)
    if (next) {
      delete params.next
      params.callback = redirectUrl
      const _queryString = qs.stringify({ ...queryObj, ...params })
      const nextUrl = `${origin}/${next}?${_queryString}`
      window.location.replace(nextUrl)
      return
    }
    handleRedirectUrl(redirectUrl, { loop: true })
    this.setState({ text: redirectUrl })
  }
  componentDidMount() {
    const webAuth = getWebAuth()
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        return console.log(err)
      }

      // The contents of authResult depend on which authentication parameters were used.
      // It can include the following:
      // authResult.accessToken - access token for the API specified by `audience`
      // authResult.expiresIn - string with the access token's expiration time in seconds
      // authResult.idToken - ID token JWT containing user profile information

      // auth0.client.userInfo(authResult.accessToken, function(err, user) {
      //   // Now you have the user's information
      // });
      // alert(JSON.stringify(authResult, null, 2))
      const token = authResult.accessToken
      this.handleRedirect(token)
    })
  }
  render() {
    // return <a href={this.state.text}>{this.state.text}</a>
    return null
    // return null
  }
}

export default Auth0
