import * as Channel from 'jschannel'

if (!window.debug) {
  window.debug = {}
  window.debug.Channel = Channel
}

export const handleRedirectUrl = (redirectUrl, { loop = false } = {}) => {
  console.log('==========================================')
  console.log('[url-handler.js - handleRedirectUrl]', redirectUrl)
  console.log('==========================================')
  window.debug.redirectUrl = redirectUrl
  if (decodeURIComponent(window.location.search).includes('platform=mobile')) {
    window.location.replace(redirectUrl)
    if (loop) {
      setInterval(() => {
        window.location.replace(redirectUrl)
      }, 500)
    }
    return
  }
  window.debug.redirectUrl = redirectUrl
  const channel = Channel.build({
    window: window.opener,
    origin: '*',
    scope: 'testScope',
  })

  channel.call({
    method: 'change_route',
    params: redirectUrl,
    success: v => {
      console.log('[v]', v)
    },
  })
  // console.log('[send]')
  // window.sysend.proxy('http://localhost:6075/proxy.html')
  // window.sysend.broadcast('change_route', redirectUrl)
}

window.debug.__h = handleRedirectUrl
