import qs from 'qs'

import { getAppConfig } from './config/app-config'
import { getCallbackUrl, getParams } from './util'

var generateRandomString = function(length) {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const Spotify = () => {
  const config = getAppConfig()
  const query = getParams()
  const state = generateRandomString(16)
  const callbackUrl = getCallbackUrl()
  const redirectUri = `${config.spotify.redirectUri}?platform=${query.platform}`
  const authorizeURL =
    'https://accounts.spotify.com/authorize?' +
    qs.stringify({
      response_type: 'code',
      client_id: config.spotify.clientId,
      scope: 'user-read-private user-read-email user-top-read',
      redirect_uri: redirectUri,
      state: state,
    })

  window.localStorage.setItem(state, JSON.stringify({ callbackUrl }))
  window.location.replace(authorizeURL)
}
